import * as am4charts from '@amcharts/amcharts4/charts'

export default (locale, priceAskLabel, priceBidLabel, data = []) => {
    const serieAsk = new am4charts.LineSeries()
    serieAsk.id = 'ask'
    serieAsk.name = `${priceAskLabel}`
    serieAsk.dataFields.dateX = 'timestamp'
    serieAsk.dataFields.valueY = 'priceAsk'
    serieAsk.tooltipText = `${priceAskLabel}: {valueY.value}`
    serieAsk.stroke = '#EE7219'
    serieAsk.fill = '#EE7219'
    serieAsk.strokeWidth = 2

    const serieBid = new am4charts.LineSeries()
    serieBid.id = 'bid'
    serieBid.name = `${priceBidLabel}`
    serieBid.dataFields.dateX = 'timestamp'
    serieBid.dataFields.valueY = 'priceBid'
    serieBid.tooltipText = `${priceBidLabel}: {valueY.value}`
    serieBid.stroke = '#004F9F'
    serieBid.fill = '#004F9F'
    serieBid.strokeWidth = 2

    const config = {
        type: 'XYChart',
        data: [...data],
        language: {
            locale: locale
        },
        xAxes: [{
            type: 'DateAxis',
            baseInterval: {
                timeUnit: 'minute',
                count: 1
            },
            groupInterval: {
                timeUnit: 'minute',
                count: 1
            },
            keepSelection: true
        }],
        yAxes: [{
            type: 'ValueAxis'
        }],
        cursor: {
            type: 'XYCursor'
        },
        legend: {
            type: 'Legend'
        },
        series: [serieAsk, serieBid],
        scrollbarX: {
            type: 'XYChartScrollbar',
            series: [serieAsk, serieBid]
        }
    }
    return config
}
