<template>
    <div class="bg-white cyrrus-rounded p-4 shadow mb-4">
        <div class="mb-4 row">
            <div class="col-12 col-md-6">
                <h4 class="mb-1">
                    <span class="font-weight-bold">{{fromSymbol}}</span>
                    <span data-icon="mdi:arrow-right" class="iconify text-cyrrus-yellow mx-2 d-inline-block"></span>
                    <span class="font-weight-bold">{{toSymbol}}</span>
                </h4>
                <p class="mb-0">
                    <span class="mr-2">{{$t('rates.chart.lastRate')}}</span>
                    <span class="mr-2 text-cyrrus-orange font-weight-bold">{{$t('rates.chart.lastRatePriceBid', { ...lastRate })}}</span>
                    <span class="mr-2 text-cyrrus-blue font-weight-bold">{{$t('rates.chart.lastRatePriceAsk', { ...lastRate })}}</span>
                    <br/><span class="mr-2">{{$t('rates.chart.lastRateTimestamp', { timestamp: (new Date(lastRate.timestamp)).toLocaleString() })}}</span>
                </p>
            </div>
            <div class="col-12 col-md-6 text-md-right align-self-end">
                <div class="d-inline-block w-25 text-left">
                    <form-input-select
                        size="sm"
                        :options="periodOptions"
                        v-model="days"
                    />
                </div>
            </div>
        </div>
        <div v-if="rates.length === 0" class="alert alert-info text-center">{{$t('rates.noRates')}}</div>
        <div v-show="rates.length > 0" class="currency-chart" :id="`chart${chartId}`"></div>
        <div class="row">
            <div class="col-12 col-md-6">

            </div>
            <div class="col-12 col-md-6 text-md-right">
                <b-btn
                    variant="primary"
                    size="sm"
                    @click.prevent="onRefresh"
                    :disabled="loading"
                    class="mr-2"
                >
                    <span class="iconify" data-icon="mdi:reload"></span> {{$t('rates.chart.refresh')}}
                </b-btn>
                <b-btn
                    variant="danger"
                    size="sm"
                    @click.prevent="onRemove"
                >
                    <span class="iconify" data-icon="mdi:trash-outline"></span> {{$t('rates.chart.remove')}}
                </b-btn>
            </div>
        </div>
    </div>
</template>

<script>
import * as am4core from '@amcharts/amcharts4/core'
import chartConfig from './chartConfig'
import enUS from '@amcharts/amcharts4/lang/en_US'
import csCZ from '@amcharts/amcharts4/lang/cs_CZ'

export default {
    name: 'RateChart',
    props: {
        chartId: {
            required: true
        },
        fromSymbol: {
            type: String,
            required: true
        },
        toSymbol: {
            type: String,
            required: true
        },
        period: {
            type: Number,
            required: false,
            default: 1
        }
    },
    data () {
        return {
            rates: [],
            loading: true
        }
    },
    computed: {
        periodOptions () {
            return [
                {
                    value: 1,
                    text: this.$t('rates.chart.periodDay')
                },
                {
                    value: 7,
                    text: this.$t('rates.chart.periodWeek')
                },
                {
                    value: 30,
                    text: this.$t('rates.chart.periodMonth')
                }
            ]
        },
        days: {
            get () {
                return this.period
            },
            set (value) {
                this.$emit('update', { id: this.chartId, period: value })
            }
        },
        lang () {
            return this.$store.state.lang
        },
        currencyPair () {
            return `${this.fromSymbol}_${this.toSymbol}`
        },
        accountId () {
            return this.$store.state.selectedAccount.account_id
        },
        lastRate () {
            if (this.rates.length > 0) {
                return {
                    ...this.rates[this.rates.length - 1]
                }
            }
            return {
                currencyPair: this.currencyPair,
                priceAsk: 0,
                priceBid: 0,
                timestamp: new Date().toISOString()
            }
        }
    },
    watch: {
        period (newValue) {
            this.$nextTick(() => {
                this.onRefresh()
            })
        },
        rates (newValue) {
            this.$nextTick(() => {
                if (this._chart) {
                    this._chart.data = [...newValue].map((item) => {
                        return {
                            priceAsk: item.priceAsk,
                            priceBid: item.priceBid,
                            timestamp: new Date(item.timestamp)
                        }
                    })
                }
            })
        },
        lang (newValue) {
            this.$nextTick(() => {
                this.renderChart()
            })
        }
    },
    mounted () {
        this.onRefresh()
        this.renderChart()
        this._refreshInterval = setInterval(this.onRefresh, 1000 * 60)
    },
    beforeDestroy () {
        if (this._chart) {
            this._chart.dispose()
        }
        clearInterval(this._refreshInterval)
    },
    methods: {
        onRemove () {
            this.$emit('remove')
        },
        async onRefresh () {
            this.loading = true
            try {
                const response = await this.$api.rates.getRatesForChart(this.currencyPair, this.accountId, this.period)
                if (response.status === 200) {
                    this.rates = [...response.data]
                }
            } catch (error) {
                console.error(error)
            } finally {
                this.$nextTick(() => {
                    this.loading = false
                })
            }
        },
        renderChart () {
            if (this._chart) {
                this._chart.dispose()
            }
            this._chart = am4core.createFromConfig(
                chartConfig(
                    ((this.$store.state.lang === 'cz') ? csCZ : enUS),
                    this.$t('rates.chart.priceAskLabel'),
                    this.$t('rates.chart.priceBidLabel'),
                    this.rates.map((item) => {
                        return {
                            priceAsk: item.priceAsk,
                            priceBid: item.priceBid,
                            timestamp: new Date(item.timestamp)
                        }
                    })
                ),
                `chart${this.chartId}`
            )
        }
    }
}
</script>

<style>
.currency-chart {
    min-height: 450px;
}
</style>
